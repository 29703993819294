<template>
  <div class="content">
    <div class="collect-wrapper">
      <!-- <search></search> -->
      <div class="item" @click="chg(0)">全部收藏</div>
      <div class="item" @click="chg(4)">视频</div>
      <div class="item" @click="chg(3)">语音</div>
      <div class="item" @click="chg(2)">图片</div>
      <!-- <div class="item" @click="chg(1)">文字</div> -->
      <div class="item" @click="chg(11)">文件</div>
    </div>
    <div class="collectinfo">
      <collection :list="list" :type="type"></collection>
    </div>
  </div>
</template>

<script>
import search from '@/components/search/search'
import collection from '@/components/collection/collection'
import { mapActions } from 'vuex'
import { getUserStore, deleteStore, textMsgs } from '@/api'
export default {
  components: {
    collection,
    search
  },
  data () {
    return {
      list: [],
      type:0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    refreshFriend(){
      
    },
    chg(type){
      this.type = type
      this.getList()
    },
    getList () {
      getUserStore({
					type:this.type
				}).then(res => {
        console.log(res)
        this.list = res.data.data
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
.content
  display flex
  width calc(100vw - 60px)
  height 100%
  .collect-wrapper
    width 260px
    height 100%
    background #fff
    box-shadow 3px 0 8px -4px rgba(0,0,0,.1)
    padding 10px
    .item
      background #007BFF
      color #fff
      margin-top 10px
      padding 8px 10px
      text-align center
      border-radius 5px
      cursor pointer
      font-size 14px
    .item:hover
      background #1844d1
  .collectinfo
    // width 550px
    width calc(100vh - 310px)
    flex 1
</style>
