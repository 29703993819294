<template>
    <div class="col-page">
      <div class="col-header">{{ getTypeTitle() }}</div>
      <div class="col-list">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="col-item"
        >
          <i
            class="el-icon-delete"
            title="点击删除收藏"
            @click.stop="handleDelete(item)"
          ></i>
          <div
            class="col-item_content"
            title="点击转发收藏"
            @click.stop="handleShare(item)"
          >
            <span v-if="item.type == 0">{{ item.content.text }}</span>
            <span v-if="item.type == 1">{{ item.content.text }}</span>
            <img
              v-if="item.type == 2"
              width="166"
              :src="chatUrl + item.list_id + '/' + item.content.url"
              alt=""
            >
            <span v-if="item.type == 3">
              <audio controls>
                <source :src="chatUrl + item.list_id + '/' + item.content.url" type="audio/ogg">
                <source :src="chatUrl + item.list_id + '/' + item.content.url" type="audio/mpeg">
                您的浏览器不支持 audio 元素。
              </audio>
            </span>
            <span v-if="item.type == 4">
              <video controls  style="width:100px;">
                <source :src="chatUrl + item.list_id + '/' + item.content.url" type="video/mp4">
              </video>
            </span>
            <div v-if="item.type == 11" target="_blank" @click222222.stop="window.open(chatUrl + item.list_id + '/' + item.content.url,'_blank')">
              <div class="address-flex">
								<div class="address-name">
									<span style="font-size: 14px;">{{item.content && item.content.name}}</span>
								</div>
							</div>
            </div>
          </div>
          <div class="col-item_com">
            <span class="col-item_user">{{ item.user_info.nickname }}</span>
            <span class="col-item_time">{{ item.time }}</span>
          </div>
        </div>
        <div class="nodata" v-if="list.length == 0">没有更多收藏内容了~</div>
      </div>
      <selectUser
        ref="selectUser"
        title="消息转发"
        tip="分别转发给"
        @handleOk="handleOk"
      ></selectUser>
    </div>
  </template>
  
  <script>
  import { getUserStore, deleteStore, textMsgs } from '@/api'
  import { mapActions } from 'vuex'
  import selectUser from '@/components/selectUser'
  const { chatUrl } = window.__gconf
  export default {
    components: {
      selectUser
    },
    props: {
        list: {
            type: Array,
            default: () => { }
        },
        type: {
            type: Number,
            default: () => { }
        }
    },
    data () {
      return {
        chatUrl: chatUrl,
        currentRow: {}
      }
    },
    methods: {
      ...mapActions([
        'fetchCharList'
      ]),
      getList () {
        getUserStore({type:this.type}).then(res => {
          console.log(res)
          this.list = res.data.data
        })
      },
      handleDelete (item) {
        deleteStore({
          id: item.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      },
      handleShare (item) {
        this.currentRow = {
          ...item
        }
        this.$refs.selectUser.open()
      },
      getTypeTitle(){
        var title ="全部收藏";
        if(this.type == 1)title ="文字";
        if(this.type == 2)title ="图片";
        if(this.type == 3)title ="语音";
        if(this.type == 4)title ="视频";
        if(this.type == 11)title ="文件";
        return title
      },
      handleOk (data) {
        textMsgs({
          list_ids: data.filter(item => item.list_id).map(item => {
            return item.list_id
          }).join(),
          content_type: this.currentRow.type,
          current_list_id: this.currentRow.list_id,
          content: JSON.stringify(this.currentRow.content)
        }).then(res => {
          this.$message.success('转发成功')
        })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .col-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    .col-header {
      height: 60px;
      padding: 28px 0 0 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #e7e7e7;
    }
    .nodata{
        color:#999;
        font-size: 12px;
        padding:20px;
        text-align: center;
    }
    .col-list {
      padding: 0 10px 16px;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      .col-item {
        padding: 16px 0;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        position: relative;
        i {
          position: absolute;
          right: 0;
          color: #adaeaf;
          cursor: pointer;
        }
        &_content {
          margin-bottom: 14px;
          cursor: pointer;
        }
        &_com {
          display: flex;
          justify-content: center;
          font-size: 12px;
          color:#666;
        }
        &_user {
          flex: 1;
          font-size: 12px;
          color:#666;
        }
      }
    }
  }
  </style>
  